.breadcrumb {
  --bs-breadcrumb-divider: '❱';
}

.breadcrumb-item>a,
.sticky-navbar-icon {
  text-decoration: none;
  color: hsla(0, 0%, 100%, .55);
}

.breadcrumb-item>a:hover,
.sticky-navbar-icon:hover {
  color: hsla(0, 0%, 100%, .75);
}

ol.breadcrumb {
  margin-bottom: 0px;
  margin-top: 0px;
}

.navbar-brand>a {
  text-decoration: none;
  color: black;
}


.clickable-card {
  /* clickable cards */
  text-decoration: none;
  color: black;
}

.clickable-card:active {
  transform: scale(0.98);
}

.choice-card:hover {
  background-color: #add8e6;
}

html {
  overflow-y: scroll;
}



/* For pdf */
.react-pdf__Page {
  margin-top: 0px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}


/* Set the background color of the fullscreen mode */
.fullscreen-enabled, :-webkit-full-screen, :-ms-fullscreen, :fullscreen {
  background: #fff;
}




/*
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
*/